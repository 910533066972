<template>
  <div
    v-show="usersList.length > 0"
    class="
      flex
      bg-gray-800
      rounded-md
      shadow-lg
      px-4
      py-2
      ring-2
      gap-y-2
      flex-col
    "
  >
    <h2 class="font-bold text-lg emoji-xl">Voted {{ reaction }}</h2>
    <div class="flex flex-row" v-for="user in usersList" :key="user">
      <img
        :src="getUser(user).avatar"
        class="w-6 h-6 rounded-full mr-1 select-none"
        draggable="false"
      />
      <p>{{ getUser(user).name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reaction: {
      type: String,
    },
    usersList: {
      type: Array,
    },
  },
  computed: {
    userData() {
      return this.$store.state.users;
    },
  },
  methods: {
    getUser(id) {
      return this.$store.getters.getUserData(id);
    },
  },
};
</script>
