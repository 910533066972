import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import LoginCallback from "../views/LoginCallback.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/auth/callback",
    name: "LoginCallback",
    component: LoginCallback,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
