import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/tailwind.css";
import Toaster from "@meforma/vue-toaster";
import Popper from "vue3-popper";
import copyText from "@meforma/vue-copy-to-clipboard";
import store from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faChevronCircleDown,
  faExclamationCircle,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";

library.add(
  faStar,
  faStarRegular,
  faChevronCircleDown,
  faExclamationCircle,
  faArrowUp
);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toaster);
app.use(copyText);
app.use(Popper);
app.component("fa-icon", FontAwesomeIcon);
app.mount("#app");
