<template>
  <div class="flex gap-6 justify-center flex-row-reverse flex-wrap-reverse">
    <post v-for="post in newPosts" :key="post" :id="post" />
  </div>
  <div class="mb-4 mt-8" v-if="newPosts.length > 0">
    <div class="flex justify-center text-red-500 items-center">
      <fa-icon :icon="['fas', 'arrow-up']" size="lg" />
      <h2 class="mx-8 text-lg">New posts</h2>
      <fa-icon :icon="['fas', 'arrow-up']" size="lg" />
    </div>
    <div class="flex justify-center">
      <div class="divide-solid divide-red-500 divide-y-4 w-9/12 mb-2">
        <div />
        <div />
      </div>
    </div>
  </div>
  <div class="flex gap-6 flex-wrap justify-center mb-24">
    <post v-for="post in posts" :key="post" :id="post" />
  </div>
</template>

<style>
img.emoji {
  height: 1rem;
  width: 1rem;
  display: inline-block;
}

.emoji-2xl > img.emoji {
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.emoji-xl > img.emoji {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
}

.emoji-md > img.emoji {
  height: 1rem;
  width: 1rem;
  display: inline-block;
}
</style>

<script>
import Post from "../components/Post.vue";
import { fdb, auth } from "../firebase";
import {
  collection,
  query,
  // getDocs,
  doc,
  getDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

export default {
  name: "Home",
  components: {
    Post,
  },
  data() {
    return {
      authenticatedOnce: false,
    };
  },
  mounted() {
    console.log("Home");
    this.$store.commit(
      "setVolume",
      window.localStorage.getItem("defaultVolume") || 0.75
    );
    auth.onAuthStateChanged((user) => {
      console.log("authenticated");
      if (user) {
        if (this.authenticatedOnce) {
          return;
        }

        this.authenticatedOnce = true;

        getDoc(doc(fdb, "users", user.uid)).then((userDoc) => {
          this.$store.commit("setMyUser", {
            id: user.uid,
            data: userDoc.data(),
          });

          // getDocs(
          //   query(collection(fdb, "posts"), orderBy("createdAt", "desc"))
          // ).then((snapshot) => {
          //   this.$store.commit("addPosts", snapshot);
          // });

          onSnapshot(
            query(collection(fdb, "posts"), orderBy("createdAt", "desc")),
            (snapshot) => {
              snapshot.docChanges().forEach((change) => {
                if (change.type == "added") {
                  if (this.$store.state.initialLoadComplete) {
                    const data = change.doc.data();
                    const author = this.$store.getters.getUserData(data.author);
                    this.$toast.info(
                      `${author.name} just posted something new!`,
                      { duration: 5000, position: "bottom" }
                    );
                    this.$store.commit("newPost", change.doc);
                  } else {
                    this.$store.commit("addPost", change.doc);
                  }
                } else if (change.type == "modified") {
                  this.$store.commit("updatePost", change.doc);
                }
              });
              this.$store.commit("initialLoadComplete");
            }
          );
        });
      } else {
        window.location.href = "/api/auth/login";
        // window.location.href =
        //   "http://localhost:5001/lotai-shitpost/us-central1/api/auth/login";
      }
    });
  },
  computed: {
    posts() {
      return this.$store.state.posts;
    },
    newPosts() {
      return this.$store.state.newPosts;
    },
    globalVolume() {
      return this.$store.state.volume;
    },
  },
  watch: {
    globalVolume(v) {
      window.localStorage.setItem("defaultVolume", v);
    },
  },
};
</script>
