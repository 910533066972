<template>
  <div class="bg-gray-800 rounded-md shadow-lg px-1 py-1 ring-2">
    <div
      v-for="option in options"
      :key="option"
      @click="clickOption(option)"
      class="
        text-white
        hover:bg-red-500
        group
        flex
        rounded-md
        items-center
        w-full
        px-2
        py-1
        text-md
        font-semibold
        cursor-pointer
        select-none
        transition
        ease-in-out
        duration-50
      "
    >
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    clickOption(option) {
      this.$emit("optionClicked", option);
    },
  },
};
</script>
