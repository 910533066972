<template>
  <div
    class="
      bg-gray-800
      rounded-lg
      max-w-xl
      px-4
      py-4
      text-left
      drop-shadow-lg
      flex-grow flex-shrink-0
      md:mx-4
    "
    style="min-width: 28rem"
  >
    <div class="mb-2">
      <div class="float-right ml-2">
        <div
          class="
            flex
            items-center
            bg-gradient-to-r
            from-red-500
            via-rose-500
            to-pink-500
            shadow-md
            px-2
            py-1
            rounded-md
          "
        >
          <img
            :src="user.avatar"
            class="w-6 h-6 rounded-full mr-1 select-none"
            draggable="false"
          />
          <p class="font-medium cursor-default">
            {{ user.username }}#{{ user.discriminator }}
          </p>
        </div>
      </div>
      <p class="font-semibold text-xl emoji-2xl items-center">
        {{ data.title }}
      </p>
      <div class="flex items-center">
        <Popper :hover="true">
          <div class="flex items-center">
            <p class="text-gray-300 text-sm cursor-default mr-1 emoji-lg">😎</p>
            <p class="text-gray-300 text-sm mr-2 cursor-default">
              {{ data.coolVotes.length }}
            </p>
          </div>
          <template #content>
            <UserReactedPopout reaction="😎" :usersList="data.coolVotes" />
          </template>
        </Popper>
        <Popper :hover="true">
          <div class="flex items-center">
            <p class="text-gray-300 text-sm cursor-default mr-1 emoji-lg">💩</p>
            <p class="text-gray-300 text-sm mr-2 cursor-default">
              {{ data.shitVotes.length }}
            </p>
          </div>
          <template #content>
            <UserReactedPopout reaction="💩" :usersList="data.shitVotes" />
          </template>
        </Popper>
        <p class="text-gray-300 text-sm cursor-default mr-2">•</p>
        <Popper :hover="true" placement="right" offsetDistance="0">
          <p class="text-gray-300 text-sm mr-2 cursor-default">
            {{ timeSincePosted }}
          </p>
          <template #content>
            <div class="bg-black bg-opacity-60 rounded-md px-2 py-1 shadow-md">
              <p class="text-sm">{{ timeText }}</p>
            </div>
          </template>
        </Popper>
      </div>
    </div>
    <video
      class="w-full h-96 rounded-lg video-js mb-4"
      ref="player"
      controls
      preload="auto"
    >
      <source :src="data.url" type="video/mp4" />
    </video>
    <div class="flex justify-between">
      <div class="flex gap-x-2">
        <ReactionButton
          :selected="rating == 'cool'"
          text="😎"
          @click="reactionBtn('cool')"
        />
        <ReactionButton
          :selected="rating == 'shit'"
          text="💩"
          @click="reactionBtn('shit')"
        />
      </div>
      <div class="flex gap-x-2 items-center">
        <Popper
          offsetDistance="6"
          placement="bottom"
          :show="showCopyLinkPopper"
        >
          <Button text="Copy Link" @click="copyLink" />
          <template #content>
            <div
              class="bg-green-500 shadow-md rounded-lg px-4 py-2 bg-opacity-70"
            >
              <p class="text-md cursor-default select-none">Copied!</p>
            </div>
          </template>
        </Popper>
        <fa-icon
          class="cursor-pointer hover:rotate-45 transform duration-75"
          @click="favouriteBtn"
          :class="favourite ? `fill-current text-yellow-500` : ''"
          :icon="[favourite ? 'fas' : 'far', 'star']"
          size="lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import Button from "../components/Button.vue";
import ReactionButton from "../components/ReactionButton.vue";
import UserReactedPopout from "../components/UserReactedPopout.vue";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
import { fdb } from "../firebase";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import twemoji from "twemoji";

dayjs.extend(relativeTime);

export default {
  data() {
    return {
      player: null,
      showCopyLinkPopper: false,
      copyLinkPopperTimeout: null,
      time: dayjs(),
      timeText: "",
      timeSincePosted: "",
      startedPlaying: false,
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  methods: {
    copyLink() {
      this.$copyText(this.data.url);
      this.showCopyLinkPopper = true;
      if (this.copyLinkPopperTimeout) {
        clearTimeout(this.copyLinkPopperTimeout);
      }
      this.copyLinkPopperTimeout = setTimeout(() => {
        this.showCopyLinkPopper = false;
        this.copyLinkPopperTimeout = null;
      }, 1000);
    },
    favouriteBtn() {
      updateDoc(doc(fdb, "posts", this.id), {
        favourites: this.favourite
          ? arrayRemove(this.$store.getters.me.id)
          : arrayUnion(this.$store.getters.me.id),
      });
    },
    reactionBtn(reaction) {
      updateDoc(doc(fdb, "posts", this.id), {
        coolVotes:
          reaction == "cool"
            ? arrayUnion(this.$store.getters.me.id)
            : arrayRemove(this.$store.getters.me.id),
        shitVotes:
          reaction == "shit"
            ? arrayUnion(this.$store.getters.me.id)
            : arrayRemove(this.$store.getters.me.id),
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.getPostData(this.id);
    },
    user() {
      return this.$store.getters.getUserData(this.data.author);
    },
    rating() {
      if (this.data.coolVotes.includes(this.$store.getters.me.id)) {
        return "cool";
      } else if (this.data.shitVotes.includes(this.$store.getters.me.id)) {
        return "shit";
      }
      return "";
    },
    favourite() {
      return this.data.favourites.includes(this.$store.getters.me.id);
    },
    globalVolume() {
      return this.$store.state.volume;
    },
  },
  mounted() {
    try {
      this.time = dayjs.unix(parseInt(this.data.createdAt.seconds));
    } catch {
      this.time = dayjs();
    }
    this.timeSincePosted = this.time.fromNow() || "unknown";
    this.timeText = this.time.format("dddd, MMMM D, YYYY h:mm A");
    this.player = videojs(this.$refs.player, {}, () => {
      this.player.volume(this.$store.state.volume);
      this.player.on("play", () => (this.startedPlaying = true));
      this.player.on("volumechange", () =>
        this.$store.commit("setVolume", this.player.volume())
      );
    });
    setInterval(() => {
      this.timeSincePosted = this.time.fromNow() || "unknown";
    }, 5000);
    twemoji.parse(document.body, { folder: "svg", ext: ".svg" });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    globalVolume(v) {
      if (this.player && !this.startedPlaying) {
        this.player.volume(v);
      }
    },
  },
  components: {
    Button,
    ReactionButton,
    UserReactedPopout,
  },
};
</script>
