import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCF2_b5_TEzpWhI7D7-6HGyOqc9oVG2lQg",
  authDomain: "lotai-shitpost.firebaseapp.com",
  projectId: "lotai-shitpost",
  storageBucket: "lotai-shitpost.appspot.com",
  messagingSenderId: "768922384802",
  appId: "1:768922384802:web:454b9cd31fff1cc788dafb",
  measurementId: "G-9T3JBZP0WR",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const fdb = getFirestore(app);

export { analytics, auth, fdb };
