<template>
  <div
    class="flex bg-gray-800 rounded-md shadow-lg px-6 py-4 ring-2 flex-col"
    style="min-width: 32rem"
  >
    <h1 class="text-xl font-bold mb-4">A new shitpost huh?</h1>
    <TextInput
      placeholder="Give your post a fancy name"
      maxlength="50"
      v-model="title"
      class="mb-4"
    />
    <form-validation-error
      class="mb-2"
      v-if="url && !isValidLink"
      error="Link isn't correct. Only Discord CDN links are accepted at this point."
    />
    <TextInput placeholder="Discord video link" v-model="url" class="mb-4" />
    <div class="flex justify-between">
      <Button
        text="Let's post it!"
        :disabled="!isValidLink || !title"
        @click="done"
      />
      <Button text="Nevermind" @click="cancel" />
    </div>
  </div>
</template>

<script>
import TextInput from "./TextInput.vue";
import FormValidationError from "./FormValidationError.vue";
import Button from "./Button.vue";
import { fdb } from "../firebase";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";

export default {
  data() {
    return {
      title: "",
      url: "",
    };
  },
  components: {
    TextInput,
    FormValidationError,
    Button,
  },
  methods: {
    done() {
      if (!this.isValidLink || !this.title) {
        return;
      }
      addDoc(collection(fdb, "posts"), {
        title: this.title,
        url: this.url,
        coolVotes: [this.$store.getters.me.id],
        shitVotes: [],
        favourites: [],
        author: this.$store.getters.me.id,
        totalScore: 1,
        createdAt: serverTimestamp(),
      });
      this.title = "";
      this.url = "";
      this.$emit("cancel");
    },
    cancel() {
      this.title = "";
      this.url = "";
      this.$emit("cancel");
    },
  },
  computed: {
    isValidLink() {
      const pattern =
        /https:\/\/cdn\.discordapp\.com\/attachments\/[0-9]{16,18}\/[0-9]{16,18}\/.{1,}\.[(mp4)(mov)(webm)]/;
      return pattern.test(this.url);
    },
  },
};
</script>
