<template>
  <div
    class="
      py-4
      shadow-xl
      mb-12
      flex
      justify-between
      flex-wrap
      gap-y-2
      px-6
      bg-gray-800
      sticky-header
      z-10
    "
  >
    <div class="flex items-center">
      <img
        src="/lotai-logo-small-retard2.png"
        class="w-16 h-16 mr-2 select-none"
        draggable="false"
      />
      <h1 class="text-5xl font-thin px-2">shitpost</h1>
    </div>
    <div class="flex items-center">
      <Popper placement="bottom-start">
        <Button text="New Shitpost" class="mr-8" />
        <template #content="{ close }">
          <new-shitpost-form @cancel="close" />
        </template>
      </Popper>
      <div class="flex items-center">
        <img
          :src="$store.getters.me.avatar"
          class="w-12 h-12 mr-2 rounded-full select-none"
          draggable="false"
        />
        <p class="font-semibold text-lg mr-2">{{ $store.getters.me.name }}</p>
        <Popper placement="bottom-start">
          <fa-icon :icon="['fas', 'chevron-circle-down']" size="lg" />
          <template #content>
            <DropdownMenu
              :options="['Logout']"
              @optionClicked="userMenuOption"
            />
          </template>
        </Popper>
      </div>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #fffb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffff;
}

.c-toast {
  @apply shadow-md py-2 px-4 rounded-lg #{!important};
}
</style>

<script>
import Button from "./components/Button.vue";
import DropdownMenu from "./components/DropdownMenu.vue";
import NewShitpostForm from "./components/NewShitpostForm.vue";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";

export default {
  components: {
    Button,
    DropdownMenu,
    NewShitpostForm,
  },
  methods: {
    userMenuOption(option) {
      if (option == "Logout") {
        signOut(auth);
      }
    },
  },
};
</script>
