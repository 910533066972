<template>
  <div class="flex justify-center">
    <div class="bg-white rounded-xl px-4 py-4 max-w-2xl mt-8 flex-grow">
      <h1 class="text-black text-4xl font-medium mb-8">{{ title }}</h1>
      <p class="text-black text-lg mb-4">{{ status }}</p>
      <p class="text-black text-md">
        This may take a few seconds, don't close this page pls ty :)
      </p>
      <Button
        v-if="showLoginButton"
        text="Let's try that again"
        class="mt-12 mb-2"
        @click="loginAgain"
      />
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import axios from "axios";
import { auth } from "../firebase";
import { signInWithCustomToken } from "firebase/auth";

export default {
  data() {
    return {
      title: "Logging in...",
      status: "Loading...",
      showLoginButton: false,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    this.status = "Verifying login and doing some magic...";
    axios
      .get("https://shitpost.lotai.xyz/api/auth/authenticate", {
        params: {
          code: urlParams.get("code"),
        },
      })
      .then((response) => {
        this.status = "Logging into server and saving details...";
        const token = response.data.token;
        signInWithCustomToken(auth, token).then(() => {
          this.status = "All done!  Thanks for your patience :)";
          window.localStorage.setItem("expectSignedIn", true);
          this.$router.replace("/");
        });
      })
      .catch((error) => {
        this.title = "Login failed :(";
        this.status = error.response.data.error || "Unknown error";
        this.showLoginButton = true;
      });
  },
  methods: {
    loginAgain() {
      window.location.href = "/api/auth/login";
    },
  },
  components: {
    Button,
  },
};
</script>
