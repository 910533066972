import { createStore } from "vuex";
import { fdb } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

export default createStore({
  state: {
    myUserId: "",
    users: {},
    postsData: {},
    newPostsData: {},
    posts: [],
    newPosts: [],
    processingUsers: [],
    initialLoadComplete: false,
    volume: 0.75,
  },
  mutations: {
    setMyUser(state, data) {
      state.myUserId = data.id;
      data.data.id = data.id;
      data.data.name = data.data.username + "#" + data.data.discriminator;
      state.users[data.id] = data.data;
    },
    addPosts(state, snapshot) {
      snapshot.forEach((postDoc) => {
        const data = postDoc.data();
        state.postsData[postDoc.id] = data;
        state.posts.push(postDoc.id);
      });
    },
    addPost(state, postDoc) {
      state.postsData[postDoc.id] = postDoc.data();
      state.posts.push(postDoc.id);
    },
    newPost(state, postDoc) {
      state.postsData[postDoc.id] = postDoc.data();
      state.newPosts.push(postDoc.id);
    },
    updatePost(state, postDoc) {
      if (state.postsData[postDoc.id]) {
        state.postsData[postDoc.id] = postDoc.data();
      } else {
        state.newPostsData[postDoc.id] = postDoc.data();
      }
    },
    initialLoadComplete(state) {
      state.initialLoadComplete = true;
    },
    setVolume(state, volume) {
      state.volume = volume;
    },
  },
  getters: {
    getPostData: (state) => (id) => {
      return state.postsData[id];
    },
    getNewPostsData: (state) => (id) => {
      return state.newPostsData[id];
    },
    me: (state) => {
      return (
        state.users[state.myUserId] || {
          username: "Loading...",
          discriminator: "",
          avatar: "",
        }
      );
    },
    getUserData: (state) => (id) => {
      if (!state.users[id] && !state.processingUsers.includes(id)) {
        state.processingUsers.push(id);
        getDoc(doc(fdb, "users", id)).then((userDoc) => {
          let data = userDoc.data();
          data.name = data.username + "#" + data.discriminator;
          state.users[id] = data;
        });
      }
      return (
        state.users[id] || {
          username: "Loading...",
          discriminator: "",
          avatar: "",
        }
      );
    },
    hasNewDocs: (state) => {
      return state.newPosts.length > 0;
    },
  },
  actions: {},
  modules: {},
});
